.productPreview {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.35);
    background-color: var(--navbar-background);
    position: relative;
    transition: .3s;
    transform: perspective(180em);
    color: var(--foreground);
    text-decoration: none;
    text-align: center;
}

.productPreview::before {
    content: '';
    position:absolute;
    left:0; top:0;
    width:100%; 
    height:100%;
    display:inline-block;    
    background: white;
    opacity: 0%;
    transition: .3s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}

.note {
    display: block;
    position: absolute;
    background-color: var(--navbar-background);
    margin: .5em;
    float: right;
    text-align: right;
    padding: .7em;
    border-radius: 2em;
    font-weight: bold;
    font-size: small;
    right: 0;
}

.title {
    position: absolute;
    background-color: #000000;
    font-size: medium;
    font-weight: 700;
    margin: 0em;
    padding: .25em 0 .25em 0;
    bottom: 0;
    width: 100%;
}

.productPreviewImage {
    height: 100%;
    object-fit: cover;
}

.productPreview::after {
    content: '';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;    
    background: linear-gradient(35deg, transparent 45%, #FFFFFFFF 50%, transparent 90%);
    filter: opacity(10%);
    background-size: 290%;
    transition: .5s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}

.productPreview:hover {
    transition: .3s;
    transform: perspective(65em) rotateX(6deg) scale(1.05);
    box-shadow: 0px 12px 12px 3px rgba(0, 0, 0, 0.25);
    filter: brightness(110%);
}

.productPreview:focus-visible {
    transition: .3s;
    transform: perspective(65em) rotateX(6deg) scale(1.05);
    box-shadow: 0px 12px 12px 3px rgba(0, 0, 0, 0.25);
}

.productPreview:hover::after {
    background-size: 125%;
    filter: opacity(15%);
    transition: .5s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}
.productPreview:focus-visible::after {
    background-size: 125%;
    opacity: 15%;
    transition: .5s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}

.productPreview:hover::before {
    opacity: 5%;
    transition: .3s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}

.productPreview:focus-visible::before {
    opacity: 5%;
    transition: .3s;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1);
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .productPreview {
        margin-bottom: 2em;
    }
}