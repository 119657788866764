.products {
    display: grid;
    justify-content: space-between;
    grid-auto-flow: dense;
    grid-row-gap: 1.25em;
    grid-column-gap: 1.25em;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    position: relative;
    padding-top: 1em;
}

.productsList {
    position: relative;
    padding-top: 1em;
}

@media screen and (min-width: 480px) and (max-width: 1280px) {
    .products {
        grid-template-columns: repeat(auto-fill, minmax(125px, .50fr));
    }
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .products {
        grid-template-columns: repeat(auto-fill, minmax(125px, .50fr));
        margin-top: 1.25em;
        padding-top: 0;
    }

    .productsList {
        padding-top: .5em;
    }
}