.serviceLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  filter: saturate(1.2) brightness(1.1);
  transition: 0.15s;
  width: 100%;
  height: 6.5rem;
  border-radius: 0.15rem;
  border-width: 0 0 0 0;
  border-style: solid;
  font-weight: bold;
  font-size: 20pt;
  text-align: center;
  transition: 0.15s;
  margin: 0 1rem 1rem 1rem;
  color: var(--foreground);
  text-decoration: none;
  box-sizing: border-box;
  flex: 1 0 30%;
}

.serviceSubtitle {
  font-weight: normal;
  font-size: large;
  color: #cccccc;
}

.serviceLink:hover {
  background-color: #cccccc;
  transition: 0.15s;
}

.subtitle {
  display: block;
  text-align: center;
}

.mcMap {
  background-image: linear-gradient(-125deg, #5aad54dd 25%, #7bc344dd 100%);
}

.store {
  background-image: linear-gradient(-125deg, #3c5ecbdd 25%, #5e98e9dd 75%);
}

.share {
  background-image: linear-gradient(-125deg, #991010cc 25%, transparent 100%);
}

.jellyfin {
  background-image: linear-gradient(-125deg, #519ad6dd 25%, #9464c1dd 100%);
}

.jellyseerr {
  background-image: linear-gradient(-125deg, #5544e5dd 25%, #8e35eadd 100%);
}

.sonarr {
  background-image: linear-gradient(-125deg, #539ed7dd 25%, #61b9fcdd 100%);
}

.radarr {
  background-image: linear-gradient(-125deg, #bea44edd 25%, #f2c23fdd 100%);
}

.bazarr {
  background-image: linear-gradient(-125deg, #5e3b73dd 25%, #8e42b2dd 100%);
}

.qbit {
  background-image: linear-gradient(-125deg, #476dbddd 25%, #7facecdd 100%);
}

.prowlarr {
  background-image: linear-gradient(-125deg, #f66700dd 25%, #f1924edd 100%);
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .serviceLink {
    padding: 0.5em 0 0.5em 0;
    width: 100% !important;
    margin: 0 0 1em 0;
    height: 2.5em;
  }

  .smallTitle {
    font-size: 15pt;
  }
}
