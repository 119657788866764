.user {
  margin-left: 0;
  margin-right: 0;
}

.userInfo {
  display: grid;
  grid-template-columns: 10em 30vw 20vw;
  grid-template-rows: 5em 5em;
  grid-template-areas:
    'Icon Name -'
    'Icon Name Logout';
  background-color: var(--background-dark);
  padding: 3em 0 3em 0;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  width: 100%;
}

.image {
  width: auto;
  height: 10em;
  border-radius: 0.15em;
}

.username {
  grid-area: Name;
  padding: 0 0 0 1em;
  font-weight: bold;
  font-size: xx-large;
}

.logout {
  grid-area: Logout;
  background-image: linear-gradient(-125deg, #ec2424dd 25%, #ec5c5cdd 75%);
  filter: saturate(1.2) brightness(1.1);
  font-size: x-large;
  font-weight: bold;
  display: flex;
  border-radius: 0.1em;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--background-darker);
  justify-content: center;
  align-items: center;
  transition: 0.15s;
  color: var(--foreground);
  text-decoration: none;
  cursor: pointer;
  margin-left: 1em;
}

.logout:hover {
  background-color: white !important;
}

.close:hover {
  background-color: var(--background);
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .user {
    margin-top: var(--navbar-height );
  }

  .userInfo {
    display: grid;
    grid-template-columns: 5em 30vw 40vw;
    grid-template-rows: 2.5em 2.5em;
    padding: 1.5rem 0 1.5rem 0;
  }

  .image {
    height: 5em;
  }

  .username {
    font-size: x-large;
  }

  .logout {
    font-size: larger;
  }
}
