.inputForm {
    display: grid;
    grid-template-columns: auto 15vw;
    grid-template-rows: auto;
    text-align: center;
    padding: 0;
    font-size: x-large;
}

.input {
    background-color: var(--background-dark);
    padding: 1em;
    border-radius: .1em;
    outline: none;
    border-style: solid;
    border-width: 0px;
    transition: .15s;
    margin-right: 1em;
    color: var(--foreground);
    font-size: large;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.input::-webkit-input-placeholder {
    font-style: italic;
}

.input:focus {
    background-color: #ffffff;
    transition: .15s;
    color: #000000;
}

.button {
    display: inline-flex;
    border-radius: .1em;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    filter: saturate(1.2) brightness(1.1);
    background-size: 100%;
    background-color: #24262e;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: .15s;
    color: var(--foreground);
    background-image: linear-gradient(-125deg, #3c5ecbDD 25%, #5e98e9DD 75%);
    cursor: pointer;
    outline: none;
    border-style: solid;
    border-width: 0px;
    margin-left: auto;
    margin-right: auto;
    font-size: large;
    font-weight: bold;
}

.button:hover {
    background-color: white !important;
    transition: .15s;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .inputForm {
        grid-template-columns: auto;
        grid-template-rows: auto 2em;
        margin-bottom: 1.5em;
    }

    .input {
        margin-right: 0;
    }
    
    .button {
        margin-top: 1em;
    }
}