.serviceGroup {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .serviceGroup {
        display: block;
    }
}