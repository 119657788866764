.loginPage {
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-content: center;
  margin: auto;
}

.invalidLogin {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 3em;
  margin-bottom: 1rem;
  border-radius: 0.15em;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--error-bg);
  font-size: x-large;
  font-weight: bolder;
  transition: 0.15s;
  opacity: 100%;
}

.invalidLogin.valid {
  height: 0;
  padding: 0em;
  filter: opacity(0);
  margin-bottom: 0;
}

.form {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  width: 40rem;
  padding: 2em 0em 2em 0em;
  border-radius: 0.15em;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--background-dark);
  font-size: x-large;
}

.formText {
  font-weight: 500;
  margin-bottom: 0.3em;
  margin-top: 0em;
  font-size: 10pt;
  color: #afafaf;
}

.inputSection {
  margin-left: 5rem;
  margin-right: 5rem;
}

.input {
  background-color: #33353c;
  padding: 0.8em;
  border-radius: 0.15em;
  outline: none;
  border-style: solid;
  border-width: 0px;
  transition: 0.15s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  width: -webkit-fill-available;
  color: var(--foreground);
  font-size: large;
}

.input::-webkit-input-placeholder {
  font-style: italic;
}

.input:hover {
  background-color: #3a3c44;
  transition: 0.15s;
}

.button {
  display: flex;
  border-radius: 0.15em;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background-color: #24262e;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  transition: 0.15s;
  color: var(--foreground);
  padding: 1em;
  cursor: pointer;
  outline: none;
  border-style: solid;
  border-width: 0px;
  margin-left: auto;
  margin-right: auto;
  font-size: large;
  font-weight: bold;
}

.button:hover {
  background-color: var(--background-light);
  transition: 0.15s;
}

.logo {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex: 0 auto;
  transition: 0.25s;
  box-sizing: border-box;
  font-size: 40pt;
  font-weight: bold;
  width: 100%;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  user-select: none;
}

.logoImage {
  width: 5rem;
  padding-right: 0.5em;
}

.signInText {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 20pt;
  font-weight: 900;
  transition: 0.15s;
  opacity: 100%;
}

.notice {
  margin-top: 0.5rem;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #8f98a0;
  font-size: 12px;
  line-height: 16px;
  width: 40rem;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .loginPage {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .form {
    width: 90vw;
  }

  .formText {
    font-size: 12px;
  }

  .logo {
    font-size: 35pt;
    padding-bottom: 3rem;
  }

  .logoImage {
    width: 4rem;
  }

  .inputSection {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .invalidLogin {
    width: 100%;
    font-size: 15pt;
  }

  .signInText {
    font-size: 17pt;
  }

  .notice {
    width: 90vw;
  }
}
