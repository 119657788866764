/* FADES */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
    
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
    
.fadeIn {
    animation-duration: 0.15s;
    animation-name: fadeIn;
    animation-fill-mode: both;
}
    
.fadeOut {
    animation-duration: 0.15s;
    animation-name: fadeOut;
    animation-fill-mode: both;
    pointer-events: none
}

/* SLIDES */
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-1em);
    }
    100% {
        opacity: 1;
        transform: translateY(0em);
    }
}
    
@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateY(0em);
    }
    100% {
        transform:translateY(-1em);
        opacity: 0;
    }
}

.slideIn {
    animation-duration: 0.15s;
    animation-name: slideIn;
    animation-fill-mode: both;
}
    
.slideOut {
    animation-duration: 0.15s;
    animation-name: slideIn;
    animation-fill-mode: both;
    pointer-events: none
}