.notice {
    margin: 0;
    padding: .8rem;
    border-radius: .15rem;
    background-color: var(--notice-bg);
    font-weight: 600;
    transition: max-height .2s, margin-bottom .4s ease .1s, padding .08s ease .17s;
    line-height: 2rem;
    margin-bottom: 1rem;
    max-height: 600px;
}

.closed {
    max-height: 0;
    padding: 0 .8rem 0 .8rem;
    overflow: hidden;
    margin-bottom: 0;
}

.closeButton {
    float: right;
    cursor: pointer;
    font-size: x-large;
}

.infoIcon {
    margin-right: .8rem
}

.infoIcon svg {
    transform: scale(1.25);
}

.error {
    background-color: var(--error-bg);
}