.navItem {
  display: flex;
  flex-direction: initial;
  padding: 0.75rem;
  text-decoration: none;
  align-items: center;
  justify-content: left;
  color: var(--foreground);
  font-size: large;
  border-radius: 0em;
  font-weight: bold;
  transition: 0.15s;
  width: 9rem;
  box-sizing: border-box;
}

.navItem:hover {
  background-color: var(--foreground-darker);
  transition: 0.15s;
}

.navItem.enabled:hover {
  background-color: var(--foreground);
  color: var(--background-darker);
}

.navItem.enabled {
  background-color: var(--foreground);
  color: var(--background-darker);
  border-width: 0 0 0 0.5rem;
  border-color: #569dfbff;
  border-style: solid;
}

img {
  width: 100%;
  height: auto;
}

.logo {
  display: flex;
  border: none;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.15s;
  text-decoration: none;
  color: var(--foreground);
}

.icon {
  width: 2.25rem;
  display: inline-flex;
  padding-right: 0.5rem;
}

.externalCtx {
  transform: translateX(-1em);
  opacity: 0%;
  pointer-events: none;
  user-select: none;
}

.external {
  border-color: green;
  border-style: solid;
  border-width: 1rem;
}

.logoTitle {
  display: block;
  font-weight: bold;
  margin-top: 0.25em;
  font-size: 17pt;
  user-select: none;
}

.logo.enabled {
  filter: brightness(100%);
  background-color: #000000;
  color: var(--foreground);
}

.logo.enabled:hover {
  background-color: #000000;
  color: var(--foreground);
}

.navItemUser {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .navItem {
    height: 3.5rem;
    box-sizing: border-box;
    width: 7rem;
    font-size: medium;
    display: flex;
    justify-content: center;
    font-size: 15pt;
  }

  .navItem.enabled {
    border-width: 0 0 0.4rem 0;
    border-color: #569dfbff;
  }

  .logo {
    margin-right: 0.75em;
    margin-left: 0.75em;
    width: 2em;
    opacity: 0;
    display: none;
  }

  .navItemUser {
    display: flex;
  }

  img {
    width: 100%;
  }

  .icon {
    width: auto;
    padding-left: 0.5rem;
  }

  .itemText {
    font-size: small;
    padding-left: 0.4rem;
    display: none;
  }

  .logoTitle {
    display: none;
    font-size: x-small;
  }

  .externalCtx {
    transform: translateX(0em) translateY(-1em);
  }

  .navUserIcon {
    width: 1.7rem;
  }
}
