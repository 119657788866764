.button {
    display: flex;
    border-radius: .1em;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    background-color: var(--background-darker);
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: .15s;
}

.steam {
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: 0.03em;
    background: linear-gradient(to right, #06bfff 50%, #2b74ff 100%);
    background-size: 200%;
    background-position: right 0;
    transition: background-position .3s ease-in;
    width: auto !important;
    height: auto !important;
    margin-right: 1.8em;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 1em;
    padding-right: 4em;
    font-size: x-large;
    font-weight: bold !important;
    text-wrap: nowrap;
}

.a.button {
    color: var(--foreground);
    text-decoration: none;
}

.button:hover {
    background-color: var(--background-dark);
    transition: .15s;
}

.steam:hover {
    background-position: 0 0;
    transition: background-position 300ms ease-in;
}

.steamMini {
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: .2em !important;
    transition: .15s !important;
    font-weight: 400 !important;
    color: #888888 !important;
    margin: 0em 2em 0em 2em;
    padding: 0em .75em 0em .75em;
    width: fit-content !important;
    font-size: 11pt;
}

.steamMini:hover {
    background-color: #494949 !important;
    color: white !important;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .steam {
        margin-right: auto;
        margin-left: auto;
        padding-right: 1em;
        font-size: larger;
    }
    
    .steamMini {
        width: 100% !important;
    }

    .productLinks {
        margin: 1.5em 1.5em 1.5em 1.5em;
    }
}