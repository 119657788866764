.frame {
    display: none;
    position: relative;
    width: 100%;
    height: 100vh;
    border: none;
    margin: 0;
    padding: 0;
    z-index: 10;
    overflow: hidden;
}

.frameContainer {
    display: flex;
}

.headerLeft {
    background-color: aliceblue !important;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .frame {
        position: fixed;
        top: 3.5em;
        height: 86.7%;
    }
}