.page {
  position: relative;
  width: 100%;
  overflow: auto;
  flex: 1;

  --page-margin: 1.5rem;
  --navbar-height: 3.5rem;
}


@media screen and (min-width: 320px) and (max-width: 750px) {
  .page {
    width: auto;
    --page-margin: 4.5rem 1.5rem 4.5rem 1.5rem;
    display: block;
  }
}