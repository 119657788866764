.productDetails {
    position: relative;
    height: 100%;
}

.productDetails:before {
    position: absolute;
    z-index: -3;
    content: "";
    width: 100%;
    height: 100%;
    background: center;
    background-size: 100%;
    background-image: var(--product-background);
    background-repeat: no-repeat;
    background-position: 0px 0px;
    transform: scaleY(-1) translateY(10vh);
    filter: blur(50px) contrast(60%) brightness(50%);
    background-position-y: 0vw;
}

.productHeader:before {
    content: '';
    z-index: -2;
    position: absolute;
    background: center;
    background-size: cover;
    background-image: var(--product-background);
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    mask-image: linear-gradient(to bottom, black 80%, transparent 95%);
}

.productHeader {
    position: relative;
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto 20em 2em;
    grid-template-areas: 
    "Placeholder"
    "Game-Info"
    "Game-Info";
    height: 39rem;
}

.productInfo:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    backdrop-filter: blur(10px);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.productInfo {
    position: relative;
    z-index: 0;
    display: grid;
    grid-area: Game-Info;
    grid-template-columns: .1fr 2fr; 
    grid-template-rows: 1fr; 
    grid-template-areas: "Download Game-Name";
    margin-top: auto;
    padding: 1em 1em 1em 2em;
    border-width: 0.15rem 0 0 0;
    border-style: solid;
    border-color: #FFFFFF10;
    box-sizing: border-box;
}

.productLinks {
    display: flex;
    background-color: var(--product-details-links-bg);
    margin: 1em 0;
    padding: .25em 0 .25em 0;
    height: 2.25rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05) !important;
}

.productDetailsMetadataDescription {
    border-radius: .15em;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    background-color: var(--product-details-description-bg);
    height: fit-content;
    padding: 1.5em;
    margin: 0 0 1em 0;
    white-space: pre-wrap;
}

.productDetailsMetadataAdditional {
    border-radius: .15em;
    height: fit-content;
    padding: 1.5em;
    margin: 0 2em 1em 2em;
    white-space: pre-wrap;
}

.productDetailsMetadataTitle {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    grid-area: Game-Name;
}

.productDetailsHeader {
    width: 100%;
    height: 12vw;
    object-fit: fill;
    box-shadow: 0 30px 40px rgba(0,0,0,.1);
}

.productDetailsImage {
    width: auto;
    max-width: 20em;
    height: auto;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.35);
    background-color: var(--background-darker);
    grid-area: Game-Image;
    transition: .15s;
    margin: 0 0 0 2em;
}

.productDetailsDownloadIcon {
    margin-right: .75em;
}

.productImageDesktop {
    display: none;
}
.productImageMobile {
    display: none;
}

.productNotice {
    margin: 1rem 2rem 1rem 2rem !important;
    white-space: pre-line;
}

.a {
    text-decoration: underline;
    color: var(--foreground);
}

.productInfoSection {
    margin: 0 2rem;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .productInfoSection {
        margin: 1rem 1.5rem;
    }

    .productHeader:before {
        mask-image: none;
    }

    .productDetails:before {
        background-image: none;
        width: 0;
    }

    .productDetails {
        padding: 0em;
    }

    .productHeader {
        grid-template-columns: 1fr;
        grid-template-rows: 15rem 7rem 2rem;
        margin-bottom: 0em;
        height: 100%;
    }

    .productInfo:before {
        mask-image: none;
        backdrop-filter: none;
    }

    .productInfo {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
        "Game-Name" 
        "Download";
        margin: auto;
        padding: 1em;
        width: 100%;
    }

    .productDetailsMetadataTitle {
        text-align: center;
        margin: 0rem 0rem .5em 0rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .productDetailsMetadataDescription {
        margin: 1.5em 0;
    }

    .productDetailsImage {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1.5em;
    }

    .productImageMobile {
        display: block;
        max-width: none;
        width: 100%;
    }

    .productNotice {
        margin: 1.5em 1.5em 1.5em 1.5em !important;
    }

    .productInfo:after {
        mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    }

    .productHeader:before {
        height: 75%;
        mask-image: linear-gradient(to bottom, black 80%, transparent 98%);
    }
}