.navbar {
    display: flex;
    overflow: hidden;
    width: 9em;
    padding: 1em;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex: 0 auto;
    background-color: var(--navbar-background);
    transition: .25s;
    box-sizing: border-box;
    height: -webkit-fill-available;
}

.topbar {
    display: none;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex: 0 auto;
    background-color: var(--navbar-background);
    transition: .25s;
    box-sizing: border-box;
    height: -webkit-fill-available;
}

.navbar.expanded {
    width: 10em;
}

.navItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem;
}

.navUserIcon {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
}

.navUser {
    display: flex;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
    .navbar {
        width: 100%;
        flex-direction: initial;
        padding: 0;
        position: fixed;
        bottom: 0;
        z-index: 9;
        height: var(--navbar-height);
    }

    .navUser {
        margin-left: 0em;
        margin-right: 0em;
    }

    .navItems {
        text-align: center;
        flex-direction: row;
        margin: 0em;
        padding: 0em;
        width: 100vw;
    }

    .navUser {
        display: none;
    }

    .topbar {
        display: flex;
        width: 100%;
        flex-direction: initial;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16pt;
        padding: 0;
        position: fixed;
        top: 0;
        z-index: 9;
        height: 3.5rem;
    }

    .topbarLogo {
        width: 1.5em;
        padding-right: .5em;
    }
}